import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { BrandHeading } from '../BrandHeading.jsx';
import PricingPlan from '../Pricing/PricingPlan.jsx';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import Loading from '../Loading.jsx';

export default function PricingBody({ onClick }) {
  const [frequency, setFrequency] = useState('MONTHLY');
  const { currentPage, news, location, plans, getPlans } = useUIStore(
    (state) => ({
      currentPage: state.currentPage,
      news: state.news,
      location: state.location,
      getPlans: state.getPlans,
      plans: state.plans,
    }),
    shallow
  );

  useEffect(() => {
    getPlans();
  }, []);

  const PLANS_MAP = {
    ONCE: {
      monthly: plans.find((p) => p.slug === 'pay-as-you-go'),
      annual: plans.find((p) => p.slug === 'pay-as-you-go'),
    },
    SUB: {
      monthly: plans.find((p) => p.slug === 'adventurer-monthly-pass'),
      annual: plans.find((p) => p.slug === 'nature-lovers-yearly-subscription'),
    },
  };

  if (!plans?.length) {
    return (
      <Loading
        loading
        mode="control"
        style={{ minH: '60vh', position: 'relative' }}
      />
    );
  }

  return (
    <Flex px={[0, 10]} bg="bg.50" direction={'column'} pt={0}>
      <Box w="full" px={[2, , 4]} textAlign="center">
        <BrandHeading>Plans that fit your need</BrandHeading>
        {news.show && (
          <Alert
            status="info"
            mb={4}
            bgColor={'brand.200'}
            rounded="md"
            py={1}
            justifyContent={'center'}
          >
            <Text mr={[2, 3]} fontSize="xl">
              🎉
            </Text>
            {news.text}
          </Alert>
        )}
        {currentPage === 'pricing' && (
          <chakra.p
            mb={3}
            fontSize={['lg', , 'xl']}
            color="gray.600"
            _dark={{
              color: 'gray.400',
            }}
          >
            Simple plans for people who love nature, by people who love nature.
            Pause/Cancel anytime.
          </chakra.p>
        )}
        <Flex justify="center" mx={['auto', 0]} mb={-2}>
          <Stack
            direction="row"
            justify="space-between"
            p="2"
            textAlign="center"
            rounded="md"
            bg="gray.200"
            _dark={{
              bg: 'gray.600',
            }}
          >
            <Button
              colorScheme="brand"
              variant={frequency === 'MONTHLY' ? 'solid' : 'ghost'}
              onClick={() => setFrequency('MONTHLY')}
              px={6}
              size={['sm', 'md']}
            >
              Bill Monthly
            </Button>
            <Button
              colorScheme="brand"
              variant={frequency === 'ANNUAL' ? 'solid' : 'ghost'}
              onClick={() => setFrequency('ANNUAL')}
              px={6}
              size={['sm', 'md']}
            >
              Bill Yearly
            </Button>
          </Stack>
        </Flex>
      </Box>
      <Box maxW="7xl" pt={20} pb={[20, 1]} mx="auto">
        <SimpleGrid columns={[1, , , 2]} gap={[20, 8]}>
          <PricingPlan
            monthly={PLANS_MAP.ONCE.monthly}
            annual={PLANS_MAP.ONCE.annual}
            frequency={frequency}
            offer={false}
            onClick={onClick}
          />
          <PricingPlan
            monthly={PLANS_MAP.SUB.monthly}
            annual={PLANS_MAP.SUB.annual}
            frequency={frequency}
            onClick={onClick}
          />
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
